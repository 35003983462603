import React from 'react';
import { Col, Row } from "antd";

import Yelp from '../../images/yelp.png';
import Google from '../../images/google.png';
import AngliesList from '../../images/angies-list.png';
import Bbb from '../../images/bbb.png';
import Facebook from '../../images/facebook.png';

const Reviews = () => {
  return (
    <Row justify="center" style={{ textAlign: 'center' }} className="reviews fade-in">
      <Col xs={22} lg={20}>
        <img src={Google} alt="Google" className="review" />
        <img src={Yelp} alt="Yelp" className="review" />
        <img src={AngliesList} alt="Angies List" className="review" />
        <img src={Facebook} alt="Facebook" className="review" />
        <img src={Bbb} alt="BBB" className="review" />
      </Col>
    </Row>
  );
};

export default Reviews;