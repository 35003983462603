import React from "react"
import { Row, Col, Tabs } from "antd"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HomeServiceHighlights from "../components/HomeServiceHighlights"
import HighlightsHeader from "../components/HighlightsHeader"
import Reviews from "../components/Reviews"
import Image from "../get-image"

import "../components/ServiceHighligts/ServiceHighlights.less"

const IndexPageHomeCleaning = () => (
  <Layout className="home" ctaTarget="/quotes/home-cleaning">
    <SEO title="Home" />
    <div className="highlights">
      <Row justify="center" className="fade-in">
        <Col xs={22} lg={12}>
          <Row className="highlights">
            <Col xs={24}>
              <HighlightsHeader type="home" />
            </Col>
          </Row>
        </Col>

        <Col xs={0} lg={10}>
          <Image
            imageName="home_cleaning_img.png"
            className="highlights__head-image"
          />
        </Col>
      </Row>

      <Reviews />

      <HomeServiceHighlights />
    </div>
  </Layout>
)

export default IndexPageHomeCleaning
