import './HomeServiceHighlights.less';
import React from 'react';
import { Row, Col } from 'antd';
import HomeCleaningServices from "../HomeCleaningServices"
import WhyChooseUs from "../WhyChooseUs"
import CtasSection from "../CtasSection"

const HomeServiceHighlights = () => {
  return (
    <Row className="highlights">
      <Col xs={24}>
        <HomeCleaningServices />

        <WhyChooseUs />

        <CtasSection />
      </Col>
    </Row>
  );
};

export default HomeServiceHighlights;