import React from "react"
import { oneOf } from "prop-types"
import { Col, Row, Typography } from "antd"
import CtasRow from "../CtasRow"

const { Title } = Typography
const getUrlParams = (search) => {
  let hashes = search.slice(search.indexOf('?') + 1).split('&')
  let params = {}
  // eslint-disable-next-line 
  hashes.map(hash => {
    let [key, val] = hash.split('=')
    params[key] = decodeURIComponent(val)
  })
  return params
}

const dynamicHeader = () => {
  if (typeof window !== `undefined`) {
    const headline = getUrlParams(window.location.search).h1
    let header
    if (typeof headline !== `undefined`) {
      let headerArr = headline.toString().split(" ")
      let headerCap = []
      headerArr.forEach(el =>
        headerCap.push(el.charAt(0).toUpperCase() + el.substring(1))
      )
      header = headerCap.join(" ")
    }
    return header
  }
}

const HighlightsHeader = ({ type }) => {
  return (
    <>
      <Row>
        {dynamicHeader() ? (
          <Col xs={24} className="highlights-header">
            <Title>{dynamicHeader()}</Title>
          </Col>
        ) : (
          <Col xs={24} className="highlights-header">
            <Title>Denver's #1 choice</Title>
            <Title>for high quality, reliable</Title>
            <Title>
              {type === "home"
                ? "home cleaning professionals"
                : "commercial cleaning pros"}
            </Title>
          </Col>
        )}
      </Row>

      <CtasRow
        target={
          type === "home"
            ? "/quotes/home-cleaning"
            : "/quotes/commercial-cleaning"
        }
      />
    </>
  )
}

HighlightsHeader.propTypes = {
  type: oneOf(["home", "commercial"]),
}

export default HighlightsHeader
