import './WhyChooseUs.less';
import React from 'react';
import { Row, Col, Typography } from 'antd';
import Highlight from "../Highlight";
import FadeInSection from "../FadeInSection"

const { Title, Text } = Typography;

const data = [{
  img: 'great_people_img.png',
  title: 'Great people',
  subTitle: 'Every one of our service providers has been professionally trained and background checked. We are also fully bonded and insured',
}, {
  img: 'guarantee_img.png',
  title: '100% quality guarantee',
  subTitle: 'It is important to us that our clients are happy with our work. If you are not, just give us a call and we will make it right.',
}, {
  img: 'reveiws_img.png',
  title: 'Top notch customer service',
  subTitle: 'We are efficient and on time. We are friendly and we know our stuff. Anytime you need help, just give us a ring.',
}];

const WhyChooseUs = () => {

  return (
    <Row justify="center" className="why">
      <Col xs={20} lg={12} className="why__header">
        <FadeInSection>
          <Title level={2}>Why Choose Us?</Title>
          <Text>
            Looking for a maid company that does a good job, is easy to deal with and charges reasonable rates.
          </Text>
          <br/>
          <Text>
            Congratulations! You found it! There are many reasons why our customers like working with us but here are a few that stand out.
          </Text>
        </FadeInSection>
      </Col>

      <Col xs={24}>
        {
          data.map((item, idx) => (
            <FadeInSection key={item.title}>
              <Highlight
                img={item.img}
                title={item.title}
                subTitle={item.subTitle}
                mirror={idx % 2 !== 0}
              />
            </FadeInSection>
          ))
        }
      </Col>
    </Row>
  );
};

export default WhyChooseUs;