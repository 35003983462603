import './CtasSection.less';
import React from 'react';
import { Row, Col, Typography } from 'antd';
import CtasRow from "../CtasRow"
import { bool } from "prop-types"

const { Title } = Typography;

const CtasSection = ({ whiteBackground }) => {
  return (
    <Row justify="center" className={`ctas-section ${ whiteBackground ? 'white' : '' }`}>
      <Col xs={24}>
        <Row justify="center">
          <Col xs={24} lg={16}>
              <Title level={2}>Hire us and get the best darn cleaning service around!</Title>
              <Title level={2}>You will be happy you did.</Title>
          </Col>
        </Row>
        <section className="ctas-secondary">
          <CtasRow center target='/'/>
        </section>
      </Col>
    </Row>
  );
};

CtasSection.propTypes = {
  whiteBackground: bool,
};

export default CtasSection;