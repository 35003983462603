import './Highlight.less';
import React from 'react';
import { bool, any, string } from 'prop-types';
import { Row, Col, Typography } from 'antd';
import Image from "../../get-image"

const { Text, Title } = Typography;

const Highlight = ({ mirror, img, title, subTitle }) => {

  return (
    <Row justify="center" align="middle" className={`highlight ${mirror ? 'mirror' : ''}`}>
      <Col xs={22} lg={8} className="highlight__image-wrapper">
        <Image imageName={img} className="highlight__image" />
      </Col>

      <Col lg={2}></Col>

      <Col lg={6} className="highlight__text">
        <Title level={3}>{ title }</Title>
        <Text>{ subTitle }</Text>
      </Col>
    </Row>
  );
};

Highlight.propTypes = {
  mirror: bool,
  img: any,
  title: string,
  subTitle: string,
};

Highlight.defaultProps = {
  mirror: false,
  img: '',
  title: '',
  subTitle: '',
};

export default Highlight;