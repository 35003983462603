import "./HomeCleaningServices.less"
import React from "react"
import { Link } from "gatsby"
import { Row, Col, Typography } from "antd"
import RightArrow from "../../images/arrow-right.png"
import FadeInSection from "../FadeInSection"
import Image from "../../get-image"

const { Text, Title } = Typography

const data = [
  {
    img: (
      <Image imageName="regular_cleaning_img.png" className="service-image" />
    ),
    title: "Regular Cleaning",
    linkTo: "/quotes/home-cleaning?type=regular",
  },
  {
    img: (
      <Image imageName="moving_cleaning_img.png" className="service-image" />
    ),
    title: "Move-In/Move-Out Cleaning",
    linkTo: "/quotes/home-cleaning?type=moveInOut",
  },
  {
    img: <Image imageName="deep_cleaning_img.png" className="service-image" />,
    title: "Deep Cleaning",
    linkTo: "/quotes/home-cleaning?type=deep",
  },
  {
    img: (
      <Image
        imageName="construction_cleaning_img.png"
        className="service-image"
      />
    ),
    title: "Post-Construction Cleaning",
    linkTo: "/quotes/home-cleaning?type=postConstruction",
  },
]

const HomeCleaningServices = () => {
  return (
    <Row className={`home-services`}>
      <Col xs={24} style={{ textAlign: "center" }}>
        <Title level={2}>Home Cleaning Services</Title>
      </Col>
      {data.map(item => (
        <Col
          key={item.title}
          xs={24}
          md={12}
          lg={6}
          className="home-services__item"
        >
          <FadeInSection>
            <Link to={item.linkTo}>
              {item.img}
              <div  className="service-title">
                <Text strong>
                  <p style={{margin: 5, color: 'white'}}>
                    {item.title}
                    <br />
                    Instant quote
                  </p>
                </Text>
                <img src={RightArrow} alt="service" className="right-arrow" />
              </div>
            </Link>
          </FadeInSection>
        </Col>
      ))}
    </Row>
  )
}

export default HomeCleaningServices
