import React, { useState, useEffect } from "react"
import "./CtasRow.less"
import { Button, Col, Row } from "antd"
import { Link } from "gatsby"
import { navigate } from "gatsby"
import { bool } from "prop-types"
import { propTypes } from "react-css-burger"

const CtasRow = ({ center, target }) => {
  const [pagePath, setPagePath] = useState("")
  useEffect(() => {
    setPagePath(checkPath.join(""))
  }, [pagePath])

  let checkPath
  if (typeof window !== `undefined`) {
    checkPath = window.location.pathname.split("/")
  }
  return (
    <Row
      gutter={24}
      className="ctas-row"
      justify={center ? "center" : "start"}
      align="middle"
    >
      <Col xs={24} md={8} lg={10} xl={8} className="cta-col">
        <Button
          // onClick={() => navigate(target)}
          type="primary"
          className="primary-cta"
        >
           <Link
              to={
                pagePath === "commercial-cleaning"
                  ? "/quotes/commercial-cleaning"
                  : "/quotes/home-cleaning"
              }
            >
              {pagePath === "commercial-cleaning"
                ? "Request a quote"
                : "Instant quote"}
            </Link>
        </Button>
      </Col>

      <Col xs={24} md={8} lg={10} xl={8} className="cta-col">
        <Button type="primary" className="primary-cta">
          Call Us Directly <br/> (303) 848-3884
        </Button>
      </Col>
    </Row>
  )
}

export default CtasRow
